<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">EDIT CUSTOMER</h2></div>
      <div class="box"><el-button @click="$onCommandParams('clients')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
      <div class="mt-30" style="max-width: 600px;">
          <el-form label-position="left" ref="form" :rules="rules" label-width="160px" :model="form" size="small">
              <el-form-item label="First" prop="first">
                  <el-input v-model="form.first"></el-input>
              </el-form-item>
              <el-form-item label="Surname" prop="surname">
                  <el-input v-model="form.surname"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                  <el-input v-model="form.email"></el-input>
              </el-form-item>
              <el-form-item label="Phone" prop="phone">
                  <el-input v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="Birthday" prop="birthday">
                <el-date-picker
                  :editable="false"
                  size="medium"
                  type="date"
                  format="yyyy/MM/dd"
                  value-format="yyyy-MM-dd"
                  v-model="form.birthday">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Dependents">
                <div class="flex column lh-32">
                  <div class="pl-16 box flex justify-space-between" v-for="(c, index) in form.children" :key="index">
                    <el-button type="text" @click="onClickEditAppend(index)">
                      <span :class="{'secondary-text' : !form.children[index].isActive}">
                        {{c.first}} {{c.surname}} {{formatDate(c.birthday)}} y.o.
                      </span>
                    </el-button>
                  </div>
                  <div>
                    <el-button type="text" @click="dialogAppendVisible = true"><i class="mdi mdi-account-plus-outline"></i> Add</el-button>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Group" prop="group">
                <el-select :popper-append-to-body="false" size="small" v-model="form.group">
                  <el-option
                    v-for="item in groupsForSelect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Comment" prop="comment">
                  <el-input type="textarea" v-model="form.comment"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="default" type="primary" @click="submit('form')">Submit</el-button>
              </el-form-item>
          </el-form>
      </div>
    <el-dialog title="Dependent" :visible.sync="dialogAppendVisible">
      <el-form :model="formChild" label-width="160px" :rules="rulesChild" ref="formChild">
        <el-form-item label="Name" prop="first">
            <el-input size="medium" v-model="formChild.first" type="text" name="name" id="frmNameA" required autocomplete="name"></el-input>
        </el-form-item>
        <el-form-item label="Birthday" prop="birthday">
            <el-date-picker
              :editable="false"
              size="medium"
              width="100%"
              v-model="formChild.birthday">
            </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-switch
            v-model="formChild.isActive"
            active-text="inside"
            inactive-text="outside">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAppendVisible = false">Cancel</el-button>
        <el-button v-if="formChild.index === -1" type="primary" @click="confirmNewAppend('formChild')">Add dependent</el-button>
        <el-button v-else type="primary" @click="confirmEditAppend('formChild', formChild.index)">Edit dependent</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CreateItem',

  data () {
    return {
      rules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please input phone', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ]
      },
      rulesChild: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ]
      },
      form: {
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: '',
        children: [],
        group: '',
        comment: ''
      },
      formChild: {
        first: '',
        surname: '',
        birthday: new Date(),
        index: -1,
        isActive: ''
      },
      dialogAppendVisible: false
    }
  },

  computed: {
    ...mapGetters([
      'groupsForSelect',
      'getMemberById'
    ])
  },

  methods: {
    formatDate (date) {
      let birthday = +new Date(date)
      return ~~((Date.now() - birthday) / (31557600000))
    },
    confirmNewAppend (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.children.splice(this.form.children.length, 0, this.formChild)
          this.dialogAppendVisible = false
        }
      })
    },
    confirmEditAppend (formName, index) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.children.splice(index, 1, this.formChild)
          this.dialogAppendVisible = false
        }
      })
    },
    onClickEditAppend (index) {
      this.dialogAppendVisible = true
      this.formChild = Object.assign({}, this.form.children[index], {
        index: index, birthday: new Date(this.form.children[index].birthday)
      })
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const model = {
            id: this.form._id,
            content: this.form
          }
          this.$store.dispatch('editMember', model)
            .then(() => {
              this.$message({
                showClose: true,
                message: 'Client edited successfully',
                type: 'success'
              })
            })
            .then(() => {
              this.$onCommandParams('clients')
            })
        }
      })
    }
  },

  mounted () {
    let item = this.getMemberById[this.$route.params.id]
    if (item) {
      let children = item.hasOwnProperty('children')
        ? item.children.map(x => Object.assign({}, x))
        : []

      this.form = Object.assign(this.form, item, {
        group: item.hasOwnProperty('group') &&
          item.group.hasOwnProperty('name')
          ? item.group.name : '',
        children: children || []
      })
    }
  }
}
</script>

<style lang="scss">
</style>
